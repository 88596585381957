<template>
  <div id="folderForm">
    <el-form label-position="top" label-width="80px">
      <el-form-item label="名称" v-if="!isMoveTo">
        <span slot="label">
          <span>{{ $t('knowledgeManagement.folderManagement.form.name') }}</span>
          <span style="color: red">*</span>
        </span>
        <el-input v-model="formFolders.name" :disabled="!isEdit || ((currentNode.source === 1 || currentNode.source === 4) && isEdit) ? false : true" style="width: 100%"  :placeholder="$t('knowledgeManagement.folderManagement.form.placeholder')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('knowledgeManagement.folderManagement.form.description')" v-if="!isMoveTo">
        <el-input v-model="formFolders.description" style="width: 100%" :placeholder="$t('knowledgeManagement.folderManagement.form.placeholder')"></el-input>
      </el-form-item>
      <el-form-item label="父目录">
        <span slot="label">
          <span>{{ $t('knowledgeManagement.folderManagement.form.parent') }}</span>
          <span style="color: red">*</span>
        </span>
        <el-dropdown
            trigger="click"
            id="el-dropdown-dir"
            ref="messageDrop"
            @visible-change="elDropdownvisibleChange($event)"
            :hide-on-click="true">
              <span
                  :class="['el-dropdown-link',
                  'el-dropdown-link-dir',
                  parentDirectorySelect
                  ? 'active-el-dropdown-link': '',]">
                <span
                    v-if="parentDirectoryName"
                    class="el-dropdown-dir-content"
                >
                  {{ parentDirectoryName }}
                </span>
                <span
                    v-else
                    class="el-dropdown-placeholder"
                >请选择</span>
                <span class="el-icon">
                  <em
                      v-show="!parentDirectorySelect"
                      class="el-icon-arrow-down"
                  ></em>
                  <em
                      v-show="parentDirectorySelect"
                      class="el-icon-arrow-up"
                  ></em>
                </span>
              </span>
          <el-dropdown-menu
              id="dir-dropdown"
              slot="dropdown"
          >
            <el-dropdown-item
                class="dir-dropdown-item"
            >
              <div class="dir-tree">
                <el-tree
                    :data="moveFolderTree"
                    node-key="id"
                    :default-expanded-keys="[-1]"
                    :expand-on-click-node="false"
                    check-strictly
                    :props="typeTreeDefaultProps"
                    @node-click="nodeClick($event)"
                    :load="loadNode"
                    lazy
                >
                  <span class="custom-tree-node" slot-scope="{ data }">
                    <div class="custom-tree-node-left">
<!--                       <i :class="['el-icon-caret-right',data.showChildren?'down':'right']"-->
<!--                          @click.stop="nodeExpand(node,data)">-->
<!--                       </i>-->
                      <span class="custom-tree-node-label">
<!--                        <img src="../../../assets/images/file.png">-->
                        <span class="folder-name">{{ data.name }}</span>
                      </span>
                    </div>
                    <span v-if="data.checked"><i class="el-icon-check"></i></span>
                  </span>
                </el-tree>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <el-form-item label="可阅读权限" v-show="!isMoveTo">
        <span slot="label">
          <span>{{ $t('knowledgeManagement.folderManagement.form.readPermissions') }}</span>
          <span style="color: red">*</span>
        </span>
        <div class="permissionList" @click="setPermission">
          <template v-if="permissionList.length > 0">
<!--            <div class="set-permission">已设置</div>-->
            <div v-for="(item,index) in permissionList" :key="index"  class="permissionItem">
              <template v-if="item.type === 1">
                <div class="departAvatar">
                  <i class="iconfont guoran-chanpin"></i>
                </div>
              </template>
              <template v-else-if="item.type === 2">
                <div class="departAvatarTag">
                  <i class="iconfont guoran-biaoqian2"></i>
                </div>
              </template>
              <template v-else>
                <div class="avatar" v-if="item.name">
                  {{(item.name).substr(0,1)}}
                </div>
              </template>
              <span class="label aa" v-if="item.name">
                  <!-- <iframe v-if="isUseOpenData()" :src="item.iframeUrl" frameborder="0" ></iframe> -->
                  <open-data :openid="item.name" :type="item.type == 1 ? 'departmentName' :'userName'" v-if="isUseOpenData()"></open-data>
                  <template v-else>
                    {{item.name}}
                  </template>
                </span>
              <span class="close" @click.stop="closePermission(index)">
                <i class="iconfont guoran-shanchu"></i>
              </span>
            </div>
          </template>
          <template v-else>
            <span class="placeholder">{{ $t('knowledgeManagement.folderManagement.permissionDialog.selectPlaceholder') }}</span>
          </template>
        </div>
      </el-form-item>
      <el-form-item :label="$t('knowledgeManagement.folderManagement.form.editPermissions')" v-show="!isMoveTo && !_isLiBang()">
        <account-management ref="editAccountManagement"></account-management>
      </el-form-item>
      <el-form-item :label="$t('knowledgeManagement.folderManagement.form.managePermissions')" v-show="!isMoveTo">
        <account-management ref="accountManagement"></account-management>
      </el-form-item>
      <el-form-item :label="$t('knowledgeManagement.folderManagement.form.effectTime')" v-if="!isMoveTo && !isFolder">
        <div class="effectTimeType">
          <el-radio-group
              v-model="formFolders.takingEffectTimeType"
              @change="changeTakingTime"
          >
            <el-radio label="noLimit">{{$t('knowledgeManagement.folderManagement.form.noLimit')}}</el-radio>
            <el-radio label="custom">{{$t('knowledgeManagement.folderManagement.form.custom')}}</el-radio>
          </el-radio-group>
        </div>
        <el-date-picker
            v-if="formFolders.takingEffectTimeType === 'custom'"
            v-model="formFolders.takingEffectTime"
            type="daterange"
            :range-separator="$t('knowledgeManagement.addNewsAnnounce.range')"
            :start-placeholder="$t('knowledgeManagement.addNewsAnnounce.startDate')"
            :end-placeholder="$t('knowledgeManagement.addNewsAnnounce.endDate')"
            :default-time="['00:00:00', '23:59:59']"
            style="width: 100%;margin-top: 12px"
        >
        </el-date-picker>
      </el-form-item>
      <div class="form-item" v-show="!isMoveTo">
        <div slot="label" class="form-item-label">
          <div class="labelLeft">
            <div class="evaluate-table-switch">
              <el-switch
                  @click.native.prevent="changeSearch('switch')"
                  :width="42"
                  v-model="formFolders.textWatermark"
                  active-color="#366AFF"
                  inactive-color="#E2E2E2">
              </el-switch>
              <span
                  class="switch-open-icon"
                  @click="changeSearch('open')"
                  v-if="formFolders.textWatermark">
                <i class="iconfont guoran-a-16-17"></i>
              </span>
              <span
                  class="switch-close-icon"
                  @click="changeSearch('close')"
                  v-if="!formFolders.textWatermark">
                <i class="arsenal_icon arsenalcuo1"></i>
              </span>
            </div>
            <span class="tips-text">{{ $t('knowledgeManagement.folderManagement.form.watermark') }}</span>
          </div>
          <div class="labelRight">
            <el-popover
                placement="bottom"
                width="100"
                trigger="click"
                content=""
                ref="watermark"
            >
              <div class="variable">
                <div class="variableItem" @click="selectVariable('userName')">
                  {{ $t('knowledgeManagement.folderManagement.form.visitorName') }}
                </div>
                <div class="variableItem" @click="selectVariable('phone')">{{ $t('knowledgeManagement.folderManagement.form.visitorPhone') }}</div>
              </div>
              <span slot="reference" v-show="formFolders.textWatermark">{{ $t('knowledgeManagement.folderManagement.form.insertVariable') }}</span>
            </el-popover>
          </div>
        </div>
        <ckeditor
            :editor="ckeditor.editor"
            v-model="formFolders.textWatermarkValue"
            :config="editorConfig"
            @focus="(zh,editor)=>currentEditor=editor"
            @ready="(editor)=>currentEditor=editor"
            v-if="formFolders.textWatermark"
        ></ckeditor>
      </div>
      <div class="form-item download" v-show="!isMoveTo">
        <div slot="label" class="form-item-label">
          <div class="labelLeft">
            <div class="evaluate-table-switch">
              <el-switch
                  @click.native.prevent="changeDownSearch('switch')"
                  :width="42"
                  v-model="formFolders.isDownload"
                  active-color="#366AFF"
                  inactive-color="#E2E2E2">
              </el-switch>
              <span
                  class="switch-open-icon"
                  @click="changeDownSearch('open')"
                  v-if="formFolders.isDownload">
                <i class="iconfont guoran-a-16-17"></i>
              </span>
              <span
                  class="switch-close-icon"
                  @click="changeDownSearch('close')"
                  v-if="!formFolders.isDownload">
                <i class="arsenal_icon arsenalcuo1"></i>
              </span>
            </div>
            <span class="tips-text">{{ $t('knowledgeManagement.folderManagement.form.isDownload') }}</span>
          </div>
        </div>
        <el-select v-model="formFolders.downloadType" :placeholder="$t('knowledgeManagement.folderManagement.permissionDialog.selectPlaceholder')" style="width: 100%" v-if="formFolders.isDownload">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </el-form>
    <employee-structure
        ref="employeeStructure"
        :permissionList="permissionList"
        @confirmPermission="confirmPermission"
        @cancelPermission="cancelPermission"
        @onNodeClick="permissionNodeClick"
        v-if="employeeStructure"
    ></employee-structure>
    <knowledge-permissions
        ref="knowledgePermissions"
        v-if="permissionDialog"
        @confirmKnowledgePermissions="confirmPermission"
    ></knowledge-permissions>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
import EmployeeStructure from "@/components/employeeStructureUpdate.vue";
import { filterTree } from "@/assets/js/filterTree";
import { GetQueryString } from "@/assets/js/filterTree";
import KnowledgePermissions from "@/components/knowledgePermissions";
import OpenData from "@/components/openData";
import AccountManagement from "@/components/accountManagement";
import BalloonEditor from 'ckeditor';
export default {
  name: "folderForm",
  components: { AccountManagement, OpenData, KnowledgePermissions, EmployeeStructure },
  data(){
    return{
      parentDirectoryName:"",
      parentDirectorySelect:false,
      typeTreeDefaultProps:{
        label:'name',
        isLeaf:'leaf'
      },
      formFolders:{
        name:"",
        description:"",
        parentId:"",
        takingEffectTimeType:"noLimit",
        takingEffectTime:"",
        textWatermarkValue:"",
        textWatermark:false,
        isDownload:false,
        downloadType:2
      },
      parentChildrenNum:0,
      directoryTree:[],
      permissionList:[],
      permissionDialog:false,
      moveFolderTree:[],
      currentUrl:"https://portal.test.askbot.cn/open-data.html",
      isFolder:true,
      permissionsObj:{
        textWatermarkValue:"",
        textWatermark:false,
        visitorWatermark:false,
      },
      permissionsListCopy:[],
      employeeStructure:false,
      editorConfig: {
        extraPlugins: [],
        toolbar: [],
        placeholder:"请输入"
      },
      ckeditor: {
        editor: BalloonEditor
      },
      currentEditor:null,
      options:[
        {
          label:"可阅读的人",
          value:2
        },
        {
          label:"可管理的人",
          value:3
        },
        {
          label:"可编辑的人",
          value:4
        },
        {
          label:"所有人",
          value:1
        }
      ]
    }
  },
  props:{
    formFolder:{
      type:Object,
      default(){
        return {}
      }
    },
    isMoveTo:{
      type:Boolean,
      default(){
        return false
      }
    },
    currentNode:{
      type:Object,
      default(){
        return {}
      }
    },
    isEdit:{
      type:Boolean,
      default(){
        return false
      }
    }
  },
  watch:{
    currentNode:{
      handler(n){
        //  // 5 语雀，6 飞书
        console.log(n,'mmmm');
      },
      deep:true,
      immediate:true
    },
    // permissionList:{
      // handler(n){
        // console.log(n,'bbbbb');
        // let corpId = sessionStorage.getItem('_corpId')
        // n.forEach(item => {
          // item.iframeUrl = `${this.currentUrl}?corpId=${corpId}&openid=${item.name}&type=${item.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${item.id + ',' + item.type + ',' + item.label}`
        // })
      // },
      // deep:true
    // }
  },
  mounted() {
    this.formFolders = this.formFolder;
    // this.getKnowledgeBaseList('');
  },
  methods:{
    loadNode(node, resolve) {
      console.debug('node',node)
      let corpId = sessionStorage.getItem('_corpId');
      let  mainId = sessionStorage.getItem('_mainId');
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      if(isAskLightning){
        url = requestUrl.knowledgeBase.clientSelectKnowledgeBaseStructureTree;
      } else {
        url = requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      }
      // let url =requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      url += "?corpId=" + corpId + '&mainId=' + mainId;
      url += '&parentId=' + (node.data.id ? node.data.id : -1)
      url += '&keyWord='
      let axiosDatas = [];
      if (node.level === 0) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data;
            axiosDatas.forEach(element => {
              element.leaf = false;
            });
            let ids = [this.formFolder.id];
            this.moveFolderTree = filterTree(axiosDatas,ids);
            this.setChecked(this.formFolder.parentId,this.moveFolderTree);
            return resolve(this.moveFolderTree);
          }
        })
      }
      if (node.level >= 1) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data
            axiosDatas.forEach(element => {
              element.leaf = false;
              element.checked=false;
            });
            let ids = [this.formFolder.id];
            axiosDatas = filterTree(axiosDatas,ids);
            this.setChecked(this.formFolder.parentId,axiosDatas);
            node.data.children = axiosDatas;
            return resolve(axiosDatas);
          }
        })
      }
    },
    getKnowledgeBaseList(departId,data){
      this.corpId = sessionStorage.getItem('_corpId');

      let url = requestUrl.knowledgeBase.knowledgeBaseTree + "?corpId=" + this.corpId + '&parentId=' + (data ? data.id : -1);
      this.$http.get(url).then(res => {
        if (res.data.code == '0'){
          this.filterCurrentNode(res.data.data);
        }
        // let axiosDatas = [];
        // axiosDatas = res.data.data.map(res => {
        //   res.showChildren = false;
        //   res.checked = false;
        //   if (res.id === this.formFolders.parentId){
        //     res.checked = true;
        //   }
        //   return res
        // });
        // if (res.data.code == '0'){
        //   if (departId){
        //     data.children = [...data.children,...axiosDatas];
        //     data.showChildren = true;
        //   } else {
        //     this.directoryTree[0].children = [...axiosDatas];
        //   }
        //   this.directoryTree[0].children.forEach(item => {
        //     if(!departId){
        //       item.showChildren = false;
        //       item.children = [];
        //     }
        //   })
        // }
      })
    },
    nodeExpand(node,data){
      data.showChildren = !data.showChildren;
      if(data.showChildren){
        this.getKnowledgeBaseList(data.id,data,node);
      } else {
        data.children = [];
      }
    },
    elDropdownvisibleChange(value) {
      if (value) {
        this.setChecked(this.formFolder.parentId,this.moveFolderTree);
      }
      this.parentDirectorySelect = value;
    },
    nodeClick(node) {
      this.$set(node, "checked", true);
      this.formFolders.parentId = node.id;
      this.parentDirectoryName = node.name;
      this.parentChildrenNum = node.children ? node.children.length : 0
      let messageDrop = this.$refs.messageDrop;
      if (messageDrop) {
        messageDrop.visible = false;
      }
    },
    //打开父目录时选中态回显
    setChecked(id,list) {
      list.forEach(item => {
        this.$set(item, "checked", false);
        if (item.children){
          this.setChecked(id,item.children)
        }
      });
      list.forEach(item => {
        if (item.id === id) {
          this.$set(item, "checked", true);
        }
        if(item.children){
          this.setChecked(id,item.children)
        }
      });
    },
    setPermission(){
      // this.permissionDialog = true;
      this.employeeStructure = true;
      this.$nextTick(() => {
        this.$refs.employeeStructure.permissionDialog = true;
        // this.$refs.employeeStructure.permissionDialog = true;
        // this.$refs.knowledgePermissions.permissionsObj.permissionsList = this.permissionList;
        // this.$refs.knowledgePermissions.permissionsListCopy = JSON.stringify(this.permissionList);
        // this.$refs.knowledgePermissions.knowledgePermissions = true;
        // this.$refs.knowledgePermissions.permissionsObj.textWatermarkValue = this.permissionsObj.textWatermarkValue;
        // this.$refs.knowledgePermissions.permissionsObj.textWatermark = this.permissionsObj.textWatermark;
        // this.$refs.knowledgePermissions.permissionsObj.visitorWatermark = this.permissionsObj.visitorWatermark;
      })
    },
    permissionNodeClick(data){
      console.log(data,'data');
      // let corpId = sessionStorage.getItem('_corpId');
      if (data.checked){
        let obj = {
          id:data.id,
          type:data.type !== undefined ? data.type : (data.source !== undefined ? 0 : 1),
          name:data.label ? data.label : data.name,
        }
        // obj.iframeUrl = `${this.currentUrl}?corpId=${corpId}&openid=${obj.name}&type=${obj.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${obj.id + ',' + obj.type + ',' + obj.label}`
        this.permissionList.push(obj)
      } else {
        this.permissionList = this.permissionList.filter(item => {
          return item.id !== data.id
        });
      }
    },
    confirmPermission(){
      // this.permissionList = list;
      // this.$refs.knowledgePermissions.knowledgePermissions = false;
      // this.permissionDialog = false;
      // this.permissionsObj = this.$refs.knowledgePermissions.permissionsObj;
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    cancelPermission(){
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
      this.employeeStructure = false;
    },
    closePermission(index){
      this.permissionList.splice(index,1);
    },
    changeTextWatermark(){
      this.permissionsObj.textWatermarkValue = "";
    },
    //根据权限id获取权限的名称
    getQueryName(list){
      let url = requestUrl.knowledgeBase.getPermissionName;
      this.$http.post(url,list).then(res => {
        if (res.data.code == '0'){
          console.log(res.data.data,'res.data.data');
          this.permissionList = res.data.data;
        }
      })
    },
    /**
     * 移动节点的时候过滤掉当前节点
     * */
    filterCurrentNode(list){
      let ids = [this.formFolder.id]
      this.moveFolderTree = filterTree(list,ids);
    },
    changeTakingTime(){
      this.formFolders.takingEffectTime = [];
    },
    changeSearch(type){
      this.formFolders.textWatermarkValue = '';
      if (type === 'close'){
        this.formFolders.textWatermark = true;
      } else if (type === 'open'){
        this.formFolders.textWatermark = false;
      }
    },
    changeDownSearch(type){
      this.formFolders.downloadType = 2;
      if (type === 'close'){
        this.formFolders.isDownload = true;
      } else if (type === 'open'){
        this.formFolders.isDownload = false;
      }
    },
    selectVariable(data){
      let command = this.currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span",
        options: {
          name: data == 'userName' ? '访问者姓名' : '访问者手机号后四位',
          data: data,
        },
      });
      this.$refs.watermark.doClose();
    }
  },
};
</script>

<style scoped lang="less">
#folderForm{
  width: 100%;
  .permissionList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 15px;
    // height: 36px;
    color: #606266;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
    /deep/.el-tag{
      margin-right: 8px;
    }
    .permissionItem{
      display: flex;
      align-items: center;
      margin-right: 8px;
      margin-bottom: 4px;
      background-color: #E8EDF7;
      border-radius: 3px;
      padding: 0px 4px;
      font-size: 12px;
      color: #010101;
      height: 26px;
      .avatar{
        flex: none;
        width: 21px;
        height: 21px;
        background: #366AFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 12px;
      }
      .departAvatar{
        flex: none;
        width: 21px;
        height:21px;
        background: #918EFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        i{
          color: #FFFFFF;
          font-size: 12px;
        }
      }
      .departAvatarTag{
        flex: none;
        width: 21px;
        height:21px;
        background: #09DDD5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        i{
          color: #FFFFFF;
          font-size: 12px;
        }
      }
      .label{
        padding: 0px 4px;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0;
        height: 26px;
        line-height: 26px;
      }
      iframe{
        height: 22px;
        cursor: pointer;
      }
    }
    .set-permission{
      color: #04BB82;
    }
    .placeholder{
      line-height: 18px;
      color: #616161;
      //color: #366aff;
    }
  }
  .effectTimeType{
    background: #fbfcfd;
    border-radius: 5px;
    padding-left: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    //margin-bottom: 12px;
  }
  .visitor-watermark{
    .tips{
      margin-left: 20px;
      color: #dcdfe6;
    }
  }
  /deep/.el-switch__label.is-active{
    color: #303133;
  }
  .form-item{
    .form-item-label{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .labelLeft{
        display: flex;
        align-items: center;
        .evaluate-table-switch {
          display: flex;
          flex-direction: row;
          height: 25px;
          justify-content: space-between;
          cursor: pointer;
          position: relative;
          text-align: center;
          align-items: center;

          .el-switch {
            text-align: center;
          }

          .switch-open-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;

            i {
              color: white;
              font-size: 16px;
            }
          }

          .switch-close-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 21px;
            i {
              color: #6e6b7b;
              font-size: 14px;
              font-weight: 600;
            }
          }

          i {
            color: #366aff;
            font-size: 18px;
          }
        }
        .tips-text{
          margin-left: 10px;
        }
      }
      .labelRight{
        color: #366aff;
        cursor:pointer;
      }
    }
    /deep/.ask-component-placeholder{
      background-color: #E4EAFF;
      color: #366aff;
      display: inline-block;
      padding: 2px 5px;
      border-radius: 3px;
      margin-right: 10px;
      margin-bottom: 8px;
    }
  }

  .download{
    margin-top: 16px;
  }

  /deep/.el-form-item__label{
    width: 100%;
  }
  /deep/.ck.ck-editor__editable_inline{
    border-color: #DCDFE6;
  }
  /deep/.el-form-item{
    margin-bottom: 16px;
  }
}
#el-dropdown-dir{
  width: 100%;
  .el-dropdown-link-dir{
    display: flex;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .el-dropdown-placeholder{
      flex: auto;
      display: block;
      height: 38px;
      padding: 0 18px;
      color: #C0C4CC;
    }
    .el-dropdown-dir-content {
      flex: auto;
      height: 38px;
      padding: 0 18px;
      max-width: calc(100% - 40px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .el-tag {
        margin: 2px 5px 0 0;
      }
    }
    .el-icon{
      flex: none;
      width: 30px;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: #FFFFFF;
}
/deep/.dir-dropdown-item{
  width: 500px!important;
  padding: 0!important;
  .dir-tree{
    max-height: 300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    ///deep/.el-tree-node__expand-icon{
    //  display: none;
    //}
    .el-tree-node__content{
      height: 32px!important;
    }
  }
  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    .custom-tree-node-left{
      display: flex;
      align-items: center;
      flex: none;
      width: calc(100% - 20px);
      .custom-tree-node-label{
        display: flex;
        align-items: center;
        width: 100%;
        flex: none;
        .folder-name{
          flex: none;
          max-width: calc(100% - 10px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        img{
          margin-right: 6px;
        }
      }
    }
    i {
      font-weight: 600;
      color: #616161;
      font-size: 14px;
    }
    .el-icon-caret-right{
      padding: 0 6px;
      color: #c0c4cc;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    .el-icon-caret-right{
      &.down{
        transform: rotate(90deg);
      }
      &.right{
        transform: rotate(0);
      }
    }
  }
  .el-tree{
    max-height: 300px;
  }
}
.variable{
  display: flex;
  flex-wrap: wrap;
  .variableItem{
    padding: 4px 6px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E4EAFF;
    color: #366aff;
    cursor:pointer;
    font-size: 12px;
  }
  .variableItem:last-child{
    margin-bottom: 0;
  }
}
</style>