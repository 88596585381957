import { render, staticRenderFns } from "./versionManage.vue?vue&type=template&id=14c9e558&scoped=true"
import script from "./versionManage.vue?vue&type=script&lang=js"
export * from "./versionManage.vue?vue&type=script&lang=js"
import style0 from "./versionManage.vue?vue&type=style&index=0&id=14c9e558&prod&lang=less"
import style1 from "./versionManage.vue?vue&type=style&index=1&id=14c9e558&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14c9e558",
  null
  
)

export default component.exports