<template>
  <div id="folderManagement">
    <div class="folderSearch" v-if="!isVersionManage && !isAskLight()">
      <el-input
          v-model="keyWord"
          :placeholder="$t('knowledgeManagement.folderManagement.folderSearch')"
          size="medium"
          suffix-icon="el-icon-search"
          @keyup.enter.native="searchFolder"
          @input="searchFolderNull"
      ></el-input>
    </div>
    <div class="folderList" v-if="!isVersionManage" :class="isAskLight() ? 'askLightFolderList':''">
      <div class="treeBox">
        <div class="knowledgeTitle" v-if="synchronizeFiles">
          {{ $t('knowledgeManagement.folderManagement.knowledgeTitle') }}
        </div>
        <el-tree
            :data="folderTree"
            node-key="id"
            :default-expanded-keys="defaultExpandedKeys"
            :expand-on-click-node="false"
            check-on-click-node
            @node-click="nodeClick"
            :props="{
              label:'name',
              children:'children',
            }"
            :current-node-key="checkDefault"
            highlight-current
            ref="treeBox"
        >
          <template #default="{ node,data }">
            <div class="custom-tree-node tree-item">
              <i
                  :class="['el-icon-caret-right',data.showChildren?'down':'right']"
                  @click.stop="nodeExpand(node,data)" v-if="data.haveChildren">
              </i>
              <i class="el-icon-caret-right" style="color: transparent" v-else></i>
              <div class="tree-item-name" :class="synchronizeFiles ? 'synchronizeTree' :'folderTree'">
                <img src="../../assets/images/file.png">
                <span class="folder-name">{{ node.label }}</span>
              </div>
              <template v-if="!isAskLight()">
                <template v-if="data.parentId == -1">
                  <el-dropdown trigger="click" @command="handleDirectory('addChildDept')" v-if="!synchronizeFiles && !data.haveChildren && !data.children.length">
                  <span class="el-dropdown-link">
                    <i class="iconfont guoran-jiahaotianjia"></i>
                  </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item>
                          {{ $t('knowledgeManagement.folderManagement.folderManagementTitle') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
                <template v-else>
                  <el-dropdown trigger="click" @command="handleCommand($event,node,data)" v-if="!synchronizeFiles && hasManagement(data)">
                  <span class="el-dropdown-link">
                    <i class="iconfont guoran-qita"></i>
                  </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item :command="'addChildDept'">
                          <i class="iconfont guoran-a-18-35"></i>
                          {{ $t('knowledgeManagement.folderManagement.subLevel') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="'addSameDept'">
                          <i class="iconfont guoran-xinjiangongdan"></i>
                          {{ $t('knowledgeManagement.folderManagement.sameLevel') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="'edit'">
                          <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
                          {{ $t('knowledgeManagement.folderManagement.edit') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="'permission'">
                          <i class="iconfont guoran-tongyichicun-shezhiquanxian"></i>
                          {{ $t('knowledgeManagement.folderManagement.permission') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="'shift'">
                          <i class="iconfont guoran-tongyichicun-yidong"></i>
                          {{ $t('knowledgeManagement.folderManagement.move') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="'del'">
                          <i class="iconfont guoran-piliangshanchu"></i>
                          {{ $t('knowledgeManagement.folderManagement.deleteText') }}
                        </el-dropdown-item>
                        <el-dropdown-item v-if="node.previousSibling" :command="'up'">
                          <i class="iconfont guoran-shangyi"></i>
                          {{ $t('knowledgeManagement.folderManagement.up') }}
                        </el-dropdown-item>
                        <el-dropdown-item v-if="node.nextSibling" command="down">
                          <i class="iconfont guoran-xiayi"></i>
                          {{ $t('knowledgeManagement.folderManagement.down') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </template>
            </div>
          </template>
        </el-tree>
        <template v-if="individualTree.length > 0 && synchronizeFiles">
          <div class="knowledgeTitle">
            个人
          </div>
          <el-tree
              :data="individualTree"
              node-key="id"
              default-expand-all
              :expand-on-click-node="false"
              check-on-click-node
              @node-click="individualNodeClick"
              :props="{
              label:'name',
              children:'children'
            }"
              :current-node-key="checkDefault"
              highlight-current
              ref="individualTreeBox"
          >
            <template #default="{ node,data }">
              <div class="custom-tree-node tree-item">
                <i
                    v-if="data.haveChildren"
                    :class="['el-icon-caret-right',data.showChildren?'down':'right']"
                    @click.stop="nodeExpand(node,data)">
                </i>
                <i class="el-icon-caret-right" style="color: transparent" v-else></i>
                <div class="tree-item-name" :class="synchronizeFiles ? 'synchronizeTree' :'folderTree'">
                  <img src="../../assets/images/file.png">
                  <span class="folder-name">{{ node.label }}</span>
                </div>
              </div>
            </template>
          </el-tree>
        </template>
      </div>
    </div>
    <delete-dialog @closeEvent="deleteFolderNoJudgeDialog = false" v-if="deleteFolderNoJudgeDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">{{ $t('knowledgeManagement.folderManagement.deleteFolderMessage') }}</div>
        <div class="prompt-text">{{ $t('knowledgeManagement.folderManagement.confirmMessage') }}</div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFolderNoJudgeDialog = false" size="small" plain class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button @click="deleteFolder" style="background-color: #FE5965 ;color: white;border:none;" size="small"  class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.deleteText') }}
        </el-button>
      </div>
    </delete-dialog>
    <popup @closeEvent="deleteFolderDialog = false" v-if="deleteFolderDialog">
      <div slot="popup-name" class="popup-name">{{ $t('knowledgeManagement.folderManagement.deleteFolder') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.folderManagement.deleteFolderConfirmation') }}</div>
      <div slot="popup-con" class="ruleForms" v-if="delFolderNameList2.length > 0">
        <el-form  ref="ruleForms" label-position="top" label-width="80px" class="demo-ruleForm">
          <el-form-item :label="$t('knowledgeManagement.folderManagement.handleFolderContent')" >
            <el-radio v-model="radio" label="remove">{{ $t('knowledgeManagement.folderManagement.moveFiles') }}</el-radio>
            <el-radio v-model="radio" label="del">{{ $t('knowledgeManagement.folderManagement.directlyDelete') }}</el-radio>
          </el-form-item>
          <el-form-item label="" v-if="radio === 'remove'">
            <el-tree
                key="chooseDept"
                ref="chooseDept"
                :data="folderTreeCopy"
                @node-click="onNodeClick"
                :expand-on-click-node="false"
                node-key="id"
                :props="{
                  label:'name',
                  isLeaf:'leaf'
                }"
                :load="loadNode"
                lazy
            >
              <span
                  class="custom-tree-node-choose-dept"
                  slot-scope="{data}">
                <span class="custom-tree-node-left">
                  <span class="data-label" >{{data.name}}</span>
                </span>
                <span v-show="data.checked">
                  <i class="el-icon-check"></i>
                </span>
              </span>
            </el-tree>
          </el-form-item>
          <div class="del-tips" v-else>
            {{ $t('knowledgeManagement.folderManagement.delTips') }}
          </div>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFolderDialog = false" plain round class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button type="primary" @click="deleteFolder" round  class="confirm-btn" style="background-color: #FE5965 ;color: white;border:none;">{{ $t('knowledgeManagement.folderManagement.deleteText') }}</el-button>
      </div>
    </popup>
    <popup @closeEvent="closeDirectoryDialog" v-if="directoryDialog" class="directoryDialog">
      <div slot="popup-name">{{ $t('knowledgeManagement.folderManagement.folderManagementTitle') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.folderManagement.directoryLevelRepresentation') }}</div>
      <div slot="popup-con" class="directoryContainer">
        <div class="directoryInput">
<!--          <textarea @keydown.tab="forbidTab($event)" @keyup.tab="replaceSpacing()"  v-model="textarea"></textarea>-->
          <el-input
              type="textarea"
              id="myInput"
              :autosize="{ minRows: 14}"
              :placeholder="$t('knowledgeManagement.folderManagement.enterContent')"
              v-model="textarea"
              @keydown.9.native.prevent="tabFunc"
          >
          </el-input>
        </div>
        <div class="directoryTree">
          <div class="header-title">
            <div class="header-left">{{ $t('knowledgeManagement.folderManagement.script.nodeDataName') }}</div>
            <div class="header-right">{{ $t('knowledgeManagement.folderManagement.permissionSecurity') }}</div>
          </div>
          <el-tree :data="directoryTree" :props="defaultProps" :expand-on-click-node="false">
            <span class="custom-tree-node" slot-scope="{ data }">
              <div class="custom-tree-node-left">
                <span class="custom-tree-node-label">{{ data.name }}</span>
              </div>
              <div class="custom-tree-node-right" @click="selectPermission(data)">
                <div class="permission">
                  <template v-if="directoryVisitPermission[data.index] && directoryVisitPermission[data.index].permissionsList && directoryVisitPermission[data.index].permissionsList.length">
                    <span style="color: #04BB82">{{ $t('knowledgeManagement.folderManagement.setUp') }}</span>
                  </template>
                  <template v-else>
                    <span style="color: #366aff">
                      {{ $t('knowledgeManagement.folderManagement.notSet') }}
                    </span>
                  </template>
                  <div class="operateBtn">
                    <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.folderManagement.UseAll')" placement="top-start">
                      <i class="iconfont guoran-quanbu1" @click.stop="userAll(data)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.folderManagement.paste')" placement="top-start">
                      <i class="iconfont guoran-niantie" @click.stop="paste(data)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.folderManagement.copy')" placement="top-start">
                      <i class="iconfont guoran-fuzhi" @click.stop="duplicateData(data)"></i>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </span>
          </el-tree>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="closeDirectoryDialog" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="confirmDirectory"  class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.save') }}</el-button>
      </div>
    </popup>
    <popup @closeEvent='editFolderDialog = false' v-if="editFolderDialog">
      <div slot="popup-name">{{editFolderTitle}}</div>
      <div slot="popup-tip">{{editFolderTips}}</div>
      <div slot="popup-con" class="popup-con">
        <folder-form :formFolder="formFolder" :directoryTree="folderTree" ref="folderForm" :isMoveTo="isMoveTo" :currentNode="nodeData" :isEdit="isEdit"></folder-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="editFolderDialog = false" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="confirmEditFolder"  class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.confirm') }}</el-button>
      </div>
    </popup>
    <employee-structure
        ref="employeeStructure"
        :permissionList="permissionList"
        @confirmPermission="confirmPermission"
        @cancelPermission="cancelPermission"
        v-if="false"
        @onNodeClick="permissionNodeClick"
    ></employee-structure>
    <knowledge-permissions
        ref="knowledgePermissions"
        v-if="permissionDialog"
        @confirmKnowledgePermissions="confirmPermission"
    ></knowledge-permissions>
    <employee-structure
        ref="employeeStructureTree"
        :permissionList="permissionList"
        v-if="false"
        @onNodeClick="permissionNodeClick"
        @cancelPermission="cancelPermission"
        @confirmPermission="confirmPermissionTree"
    ></employee-structure>
  </div>
</template>

<script>
import popup from "@/components/popup";
import FolderForm from "@/views/knowledgeManagement/components/folderForm";
import {requestUrl} from "@/http/requestUrl";
import EmployeeStructure from "@/components/employeeStructure";
import { v4 as uuidv4 } from "uuid";
import { GetQueryString } from "@/assets/js/filterTree";
import KnowledgePermissions from "@/components/knowledgePermissions";
import DeleteDialog from "@/components/deleteDialog";

export default {
  name: "folderManagement",
  components: {KnowledgePermissions, EmployeeStructure, FolderForm,popup,DeleteDialog },
  data() {
    return {
      keyWord: "",
      folderTree: [],
      deleteFolderDialog:false,
      directoryDialog:false,
      editFolderDialog:false,
      directoryTree:[],
      individualTree:[],
      defaultProps:{
        children: 'children',
        label: 'name'
      },
      textarea:"",
      nodeData:{
        id:-1,
        name:"企业知识库",
      },
      formFolder:{
        name:"",
        description:"",
        parentId:"",
        takingEffectTimeType:"noLimit",
        takingEffectTime:""
      },
      editFolderTitle:this.$t('knowledgeManagement.folderManagement.script.editFolderTitle'),
      editFolderTips:this.$t('knowledgeManagement.folderManagement.script.editFolderTips'),
      checkDefault:-1,
      nodeParentData:{
        id:-1,
        name:"企业知识库",
      },
      isMoveTo:false,
      radio:"remove",
      folderTreeCopy:[],
      checkedDeptPopup:[],
      corpId:"",
      permissionList:[],
      permissionDialog:false,
      permissionDialogTree:false,
      folderPullTree:[],
      folderPullTreeCopy:[],
      treeNode:{},
      directoryVisitPermission:{},
      searchFolderDefaultExpand:false,
      currentUrl:"https://portal.test.askbot.cn/open-data.html",
      isEdit:false, // 是否是编辑文件夹
      permissionsObj:{
        textWatermarkValue:"",
        textWatermark:false,
        visitorWatermark:false,
        permissionsList:[]
      },
      permissionTree:false,
      currentNodeCopy:null,
      duplicate:{},
      deleteFolderNoJudgeDialog:false,
      delFolderNameList2:[],
      delType:"tree",
      defaultExpandedKeys:[],
      checkParentNode:{},
      saveLoading:false
    };
  },
  props:["synchronizeFiles","channel","isVersionManage","currentNode","sourceType"],
  methods:{
    handlerDelFolder(){
      this.delFolderNameList2 = [];
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      this.$http.post(requestUrl.knowledgeBase.delCheckFolder + '?corpId=' + corpId + '&mainId=' + mainId,{
        folderIds:[this.nodeData.id]
      }).then(res => {
        if (res.data.code == '0'){
          if(res.data.data){
            for(let key in res.data.data){
              // for (let index = 0; index < this.selectFolderList.length; index++) {
              //   const item = this.selectFolderList[index];
                if(res.data.data[key] && this.nodeData.id == key){
                  this.delFolderNameList2.push(this.nodeData);
                }
              // }
            }
          }
        }
        if(this.delFolderNameList2.length > 0){
          this.deleteFolderDialog = true;
        } else {
          this.deleteFolderNoJudgeDialog = true;
        }
      })
    },
    getKnowledgeBaseList(departId,data,type){
      console.log(399, departId,data,type);
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      this.currentNodeCopy = this.currentNode;
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      if(isAskLightning){
        url = requestUrl.knowledgeBase.clientSelectKnowledgeBaseStructureTree;
      } else {
        url = requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      }
      if (this.synchronizeFiles){
        url = requestUrl.yuQueKnowledgeBase.selectSyncList
      } 
      if(this.channel === 5){
        url = requestUrl.feishu.selectSyncList;
      }
      url += "?corpId=" + corpId + '&mainId=' + mainId;
      if (this.synchronizeFiles){
        url += '&justFolder=true'
      }
      if (type !== 'search'){
      if(this.channel === 5){
        url += '&parentToken=' + (data ? data.token : -1)
      } else {
        url += '&parentId=' + (data ? data.id : -1)
      }
        // url += '&parentId=' + (data ? data.id : -1)
        this.searchFolderDefaultExpand = false;
      } else {
        this.searchFolderDefaultExpand = true;
      }
      url += '&keyWord=' + this.keyWord.trim();
      if(isAskLightning){
        let memberInDepartDto = JSON.parse(localStorage.getItem('memberInDepartDto'));
        this.$http.post(url,memberInDepartDto).then(res => {
          this.handlerAxiosData(res,departId,data,type);
        })
      } else {
        this.$http.get(url).then(res => {
          this.handlerAxiosData(res,departId,data,type);
        })
      }
    },
    // 处理接口返回数据
    handlerAxiosData(res,departId,data,type){
      console.log(departId,data,type,'departId');
      let axiosDatas = [];

      if (res.data.code == '0' && res.data.data){
        if(type !== "search"){
          axiosDatas = res.data.data.map(res => {
            this.$set(res,'showChildren',false)
            this.$set(res,'children',[]);
            return res
          });
        } else {
          axiosDatas = res.data.data.map(res => {
            this.$set(res,'showChildren', true)
            return res
          });
        }
        if (departId){
          if (!data.children){
            this.$set(data,'children',[]);
          }
          if (type == 'clear'){
            data.children = [];

          }
          this.$set(data,'notHasChildren',res.data.data.length == 0 ? true : false);
          if(typeof(type) === 'object'){
            this.$set(type.data,'children',[...data.children,...axiosDatas])
          } else {
            this.$set(data,'children',[...data.children,...axiosDatas])
          }
          this.$set(data,'showChildren',true)
          this.$set(data,'haveChildren',axiosDatas.length > 0 ? true : false);
          if (type == 'clear'){
            if(this.nodeData.name === '企业知识库' && this.nodeData.children.length === 0){
            this.nodeData.haveChildren = false
            let currentDaata = this.$refs.treeBox &&  this.$refs.treeBox.getCurrentNode(data.id)
            currentDaata.children = axiosDatas
          }
          }

        } else {
          if (this.synchronizeFiles) {
            this.folderTree = axiosDatas.filter(item => item.knowledgeType == 0 || !item.knowledgeType);
            this.individualTree = axiosDatas.filter(item => item.knowledgeType == 1)
            this.nodeData = this.folderTree[0] ? this.folderTree[0] : (this.individualTree[0] ? this.individualTree[0] : {})
          } else {
            this.folderTree = [...axiosDatas];
            this.nodeData = this.folderTree[0] ? this.folderTree[0] : {id:-1,name:'企业知识库'};
            this.nodeParentData = this.folderTree[0] ? this.folderTree[0] : {id:-1,name:'企业知识库'};
          }
        }
        if(type === 'mounted' || type === 'closeDrawer'){
          // 返回
          if(this.$route.query.isback == 1){
            this.$parent.breadcrumb = JSON.parse(sessionStorage.getItem('breadcrumb')) ? JSON.parse(sessionStorage.getItem('breadcrumb')) : [];
            this.nodeData = this.$parent.breadcrumb[this.$parent.breadcrumb.length-1]
            let fullPath = window.location.href;
            let url = fullPath.slice(fullPath.indexOf('#'),fullPath.length)
            // sessionStorage.removeItem('breadcrumb')
            if (this.$parent.replaceUrl){
              window.history.replaceState('', '', this.$parent.replaceUrl(url,['isback']));
            }
          } else {
            if(departId === ''){
              this.$parent.breadcrumb = axiosDatas;
            }
          }
          if(this.sourceType === 'knowledgeManagement'){
            let isContinue = true;
            this.defaultExpandedKeys = [];
            this.folderTree.forEach(item => {
              this.defaultExpandedKeys.push(item.id)
              if(item.children){
                item.children.forEach(childrenItem => {
                  this.defaultExpandedKeys.push(childrenItem.id)
                  if(childrenItem.children){
                    isContinue = false;
                  }
                })
              }
            })
            if(isContinue){
              if(axiosDatas[0]){
                this.getKnowledgeBaseList( axiosDatas[0].id, axiosDatas[0], type);
              }
            }
          }
        }
        // 重新展开移动到的节点时判断是否是移动状态与 开始移动时对应。如果为 true 。致此移动结束。isMoveTo 重新设置为 false
        if(this.isMoveTo) {
          let newParentNode = this.$refs.treeBox.getNode(this.nodeParentData)
          let newNode = null
          for (let index = 0; index < newParentNode.data.children.length; index++) {
            const element = newParentNode.data.children[index];
            if(element.id == this.nodeData.id) {
              newNode = element
            }
          }
          this.$nextTick(() => {
            if (newNode){
              const clickNode = this.$refs.treeBox.getNode(newNode)
              this.nodeClick(clickNode.data, clickNode)
            }
            this.isMoveTo = false
          })
        }
        setTimeout(() => {
            if (this.$refs.treeBox){
              // let newNode = this.$refs.treeBox.getNode(this.nodeData)
              this.$nextTick(() => {
                if (this.nodeParentData){
                  // this.checkDefault = this.nodeParentData.id;
                  // this.$refs.treeBox.setCurrentKey(this.nodeParentData.id)
                  // this.checkDefault = this.nodeParentData.id;
                }
              })
            }
            if (this.$refs.individualTreeBox){
              let newNode = this.$refs.individualTreeBox.getNode(this.nodeData)
              this.$nextTick(() => {
                if (newNode && newNode.data){
                  this.$refs.individualTreeBox.setCurrentKey(newNode.data.id);
                }
              })
            }
          },500)
        if ((!departId && type!=='search') || type === 'add'){
          this.$emit('getKnowledge',this.nodeData,type,type)
        }
        this.$set(this.nodeData,'showChildren',res.data.data.length > 0 ? true : false);
      } else {
        if (this.synchronizeFiles){
          this.folderTree = [];
        }
      }
    },
    setShowChildren(list){
      list.forEach(item => {
        if (!item.children || !item.children.length){
          this.$set(item,'children',[]);
          this.$set(item,'showChildren',false);
        } else {
          this.$set(item,'showChildren',true);
          this.setShowChildren(item.children)
        }
      })
    },
    nodeExpand(node,data){
      data.showChildren = !data.showChildren;
      if (this.keyWord){
        return
      }
      if(data.showChildren){
        this.getKnowledgeBaseList(data.id,data,node);
      } else {
        data.children = [];
      }
    },
    searchFolder(){
      if (this.keyWord){
        if (this.synchronizeFiles){
          this.folderTree = [];
        } else {
          this.folderTree = []
        }
        this.getKnowledgeBaseList('','','search')
      }
    },
    searchFolderNull(){
      if (!this.keyWord){
        if (this.synchronizeFiles){
          this.folderTree = [];
        } else {
          this.folderTree = []
        }
        this.getKnowledgeBaseList('','','mounted')
      }
    },
    nodeClick(data,node){
      // 初始化文件类型为全部
      this.$emit('initSearchType');
      
      this.checkParentNode = data.parentId == -1 ? data : node.parent.data;
      this.nodeParentData =  node.parent.data;
      console.log(616, data, node);
      let tagListIdsStr = localStorage.getItem("ask_tagListIds");
      let tagListIds = [];
      if (tagListIdsStr) {
        tagListIds = tagListIdsStr.split(",");
      }
      
      if (data.visitPermission && data.visitPermission.length > 0) {
        let visitPermission = JSON.parse(data.visitPermission);
        let tampVisitPermission = [];
        visitPermission.forEach((cell) => {
          // console.log(943, cellIndex, cell);
          if (cell.type == 2) {
            if (tagListIds.includes(String(cell.id))) {
              // console.log(945, cell);
              tampVisitPermission.push(cell);
            }
          } else {
            tampVisitPermission.push(cell);
          }
        })
        data.visitPermission =  JSON.stringify(tampVisitPermission);
      }
      
      this.$emit('getFolderData',data,node,'isReload')
      if (this.$refs.individualTreeBox){
        this.$refs.individualTreeBox.setCurrentKey(null)
      }
      this.nodeData = this.$refs.treeBox && this.$refs.treeBox.getCurrentNode(data.id);
      console.log(this.nodeData,'this.nodeDatathis.nodeData');
      if(!this.nodeData) {
        this.nodeData = this.$refs.treeBox && this.$refs.treeBox.getNode(data.id);
      }
    },
    individualNodeClick(data,node){
      this.nodeData = data;
      this.nodeParentData = node.parent.data
      this.$emit('getFolderData',data,node)
      if (this.$refs.treeBox){
        this.$refs.treeBox.setCurrentKey(null)
      }
    },
    handleCommand(command,node,data,deleteType){
      if(deleteType && deleteType != 'detail-edit' && deleteType != 'detail-shift' && deleteType != 'detail-delete') {
        this.nodeData = this.$refs.treeBox && this.$refs.treeBox.getCurrentNode(this.nodeData.id);
      }
      this.isMoveTo = false;
      if (command === 'addChildDept'){
        this.editFolderDialog = true;
        this.isEdit = false;
        this.editFolderTitle = this.$t('knowledgeManagement.folderManagement.script.addChildFolderSuccessMessage');
        this.editFolderTips = this.$t('knowledgeManagement.folderManagement.script.editFolderTips');
        this.formFolder = {
          name:"",
          description:"",
          parentId:this.nodeData.id,
          takingEffectTimeType:"noLimit",
          takingEffectTime:[],
          textWatermark:this.nodeData.textWatermark,
          textWatermarkValue:this.nodeData.textWatermarkValue,
          isDownload:this.nodeData.isDownload,
          downloadType:this.nodeData.downloadType ? this.nodeData.downloadType : 2
        }
        this.$nextTick(() => {
          this.$refs.folderForm.parentDirectoryName = this.nodeData.name;
          this.$refs.folderForm.parentChildrenNum = this.nodeData.children && this.nodeData.children.length;
          this.$refs.folderForm.isFolder = true;
          if (this.nodeData.visitPermission){
            let list = JSON.parse(this.nodeData.visitPermission)
            this.$refs.folderForm.permissionList = list;
          }
          let management = []
          if (this.nodeData.managePermission){
            management = JSON.parse(this.nodeData.managePermission)
          }
          // 在企业知识目录下创建子目录，默认添加当前登录人与超级管理员          
          if(this.nodeData.parentId == -1) {
            management.push({
                id: sessionStorage.getItem("_uid") * 1,
                name: sessionStorage.getItem("realname"),
                type: 0
            })
          }
          this.$refs.folderForm.$refs.accountManagement.managePermission = management;
          this.$refs.folderForm.$refs.accountManagement.managePermissionIds = management.map(res => {
            return res.id
          })
          console.log(this.$refs.folderForm.$refs.accountManagement.managePermissionIds, 'this.nodeDatathis.nodeData');
          console.log(this.$refs.folderForm.$refs.accountManagement.managePermission, 'this.nodeDatathis.nodeData');

          let editManagement = []
          if (this.nodeData.editPermission){
            editManagement = JSON.parse(this.nodeData.editPermission);
          }
          this.$refs.folderForm.$refs.editAccountManagement.managePermission = editManagement;
          this.$refs.folderForm.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
            return res.id
          })
        })
      } else if (command === 'addSameDept'){
        this.editFolderDialog = true;
         this.isEdit = false;
        this.editFolderTitle = this.$t('knowledgeManagement.folderManagement.sameLevel');
        this.editFolderTips = this.$t('knowledgeManagement.folderManagement.script.editFolderTips');
        this.formFolder = {
          name:"",
          description:'',
          parentId:this.nodeParentData.id,
          takingEffectTimeType:"noLimit",
          takingEffectTime:"",
          textWatermark:this.nodeParentData.textWatermark,
          textWatermarkValue:this.nodeParentData.textWatermarkValue,
          isDownload:this.nodeParentData.isDownload,
          downloadType:this.nodeParentData.downloadType ? this.nodeParentData.downloadType : 2
        }
        this.$nextTick(() => {
          this.$refs.folderForm.parentDirectoryName = this.nodeParentData.name;
          this.$refs.folderForm.parentChildrenNum = this.nodeParentData.children && this.nodeParentData.children.length;
          this.$refs.folderForm.isFolder = true;
          if (this.nodeParentData.visitPermission){
            let list = JSON.parse(this.nodeParentData.visitPermission)
            this.$refs.folderForm.permissionList = list
          }
          let management = []
          if (this.nodeParentData.managePermission){
            management = JSON.parse(this.nodeParentData.managePermission)
          }
            // 在企业知识目录下创建子目录，默认添加当前登录人与超级管理员          
            if(this.nodeParentData.parentId == -1) {
                management.push({
                    id: sessionStorage.getItem("_uid") * 1,
                    name: sessionStorage.getItem("realname"),
                    type: 0
                })
            }
          this.$refs.folderForm.$refs.accountManagement.managePermission = management;
          this.$refs.folderForm.$refs.accountManagement.managePermissionIds = management.map(res => {
            return res.id
          })

          let editManagement = []
          if (this.nodeData.editPermission){
            editManagement = JSON.parse(this.nodeData.editPermission);
          }
          this.$refs.folderForm.$refs.editAccountManagement.managePermission = editManagement;
          this.$refs.folderForm.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
            return res.id
          })
        })
      } else if (command === 'edit'){
        this.editFolderDialog = true;
        this.isEdit = true;
        this.editFolderTitle = this.$t('knowledgeManagement.folderManagement.edit');
        this.editFolderTips = '';
        let takingEffectTime = [];
        let takingEffectTimeType = 'noLimit'
        if (this.nodeData.validFrom && this.nodeData.validTo){
          takingEffectTimeType = 'custom';
          takingEffectTime = [this.nodeData.validFrom,this.nodeData.validTo]
        }
        this.formFolder = {
          id:this.nodeData.id,
          name:this.nodeData.name,
          description:this.nodeData.description,
          parentId:this.nodeData.parentId,
          textWatermark:this.nodeData.textWatermark,
          textWatermarkValue:this.nodeData.textWatermarkValue,
          isDownload:this.nodeData.isDownload,
          downloadType:this.nodeData.downloadType ? this.nodeData.downloadType : 2,
          takingEffectTimeType,
          takingEffectTime
        }
        this.$nextTick(() => {
          this.$refs.folderForm.parentDirectoryName = this.nodeParentData.name;
          // this.$refs.folderForm.parentChildrenNum = this.nodeParentData.children && this.nodeParentData.children.length;
          if (this.nodeData.visitPermission){
            let list = JSON.parse(this.nodeData.visitPermission)
            this.$refs.folderForm.permissionList = list;
            this.$refs.folderForm.permissionsListCopy = this.nodeData.visitPermission;
          }
          this.$refs.folderForm.isFolder = this.nodeData.type == 0 ? true : false;
          let management = []
          if (this.nodeData.managePermission){
            management = JSON.parse(this.nodeData.managePermission)
          }
          this.$refs.folderForm.$refs.accountManagement.managePermission = management;
          this.$refs.folderForm.$refs.accountManagement.managePermissionIds = management.map(res => {
            return res.id
          })

          let editManagement = []
          if (this.nodeData.editPermission){
            editManagement = JSON.parse(this.nodeData.editPermission);
          }
          this.$refs.folderForm.$refs.editAccountManagement.managePermission = editManagement;
          this.$refs.folderForm.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
            return res.id
          })
        })
      } else if (command === 'del'){
        this.checkedDeptPopup = [];
        this.radio = 'remove';
        this.delType = deleteType ? 'table' : 'tree';
        // this.deleteFolderDialog = true;
        this.handlerDelFolder()
      } else if (command === 'shift'){
        if (data.syncEnable) {
          this.$message({
            message: this.$t('AISearch.cannotBeMoved'),
            duration: 3000,
            type: "warning"
          })
          return;
        }
        // 在移动节点时 isMoveTo 变量设置为 true。后续操作会根据该字段判断是否是移动节点中执行的操作
        this.isMoveTo = true;
        this.editFolderDialog = true;
        this.isEdit = true;
        this.editFolderTitle = this.$t('knowledgeManagement.folderManagement.script.editFolderTitleMove');
        this.editFolderTips = this.$t('knowledgeManagement.folderManagement.script.editFolderTipsMover');
        let takingEffectTime = [];
        let takingEffectTimeType = 'noLimit'
        if (this.nodeData.validFrom && this.nodeData.validTo){
          takingEffectTimeType = 'custom';
          takingEffectTime = [this.nodeData.validFrom,this.nodeData.validTo]
        }
        this.formFolder = {
          id:this.nodeData.id,
          name:this.nodeData.name,
          description:this.nodeData.description,
          parentId:this.nodeData.parentId,
          textWatermark:this.nodeData.textWatermark,
          textWatermarkValue:this.nodeData.textWatermarkValue,
          isDownload:this.nodeData.isDownload,
          downloadType:this.nodeData.downloadType ? this.nodeData.downloadType : 2,
          takingEffectTimeType,
          takingEffectTime
        }
        this.$nextTick(() => {
          this.$refs.folderForm.parentDirectoryName = this.nodeParentData.name;
          this.$refs.folderForm.parentChildrenNum = this.nodeParentData.children && this.nodeParentData.children.length;
          if (this.nodeData.visitPermission){
            let list = JSON.parse(this.nodeData.visitPermission)
            this.$refs.folderForm.permissionList = list
          }
          let management = []
          if (this.nodeData.managePermission){
            management = JSON.parse(this.nodeData.managePermission)
          }
          this.$refs.folderForm.$refs.accountManagement.managePermission = management;
          this.$refs.folderForm.$refs.accountManagement.managePermissionIds = management.map(res => {
            return res.id
          })

          let editManagement = []
          if (this.nodeData.editPermission){
            editManagement = JSON.parse(this.nodeData.editPermission);
          }
          this.$refs.folderForm.$refs.editAccountManagement.managePermission = editManagement;
          this.$refs.folderForm.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
            return res.id
          })
        })
      } else if (command == 'up' || command == 'down'){
        // let list = node.parent.childNodes;
        let list = [];
        node.parent.childNodes.forEach(item => {
          if(item.name && item.parentId){
            list.push(item)
          } else {
            if(item.data.name && item.data.parentId){
              list.push(item.data)
            }
          }
        })
        let currentData = data;
        let params = [];
        if (command === 'up') {
          params = [...[currentData], ...[node.previousSibling.data]]
        } else {
          params = [...[node.nextSibling.data], ...[currentData]]
        }
        let activeIndex = 0
        list.forEach((item, index) => {
          if (currentData.id === item.id) {
            activeIndex = index
          }
        });
        if (command === 'up') {
          list[activeIndex] = params[1]
          list[activeIndex - 1] = params[0]
        }
        else {
          list[activeIndex] = params[0]
          list[activeIndex + 1] = params[1]
        }
        let url = requestUrl.knowledgeBase.moveKnowledge
        
        let sortListIds = list.map((item,index) => {
          let obj = {
            id:item.id,
            orderNum: index
          }
          return obj
        })
        this.$http.put(url,sortListIds).then(res => {
          if (res.data.code == '0'){
            this.$message({
              message:this.$t('knowledgeManagement.folderManagement.script.moveSuccess'),
              type:'success',
              duration:2000
            })
            // let node = this.getParentNode(this.folderTree);
            // console.debug('node',node)
            setTimeout(() => {
              // this.folderTree = []
              this.getKnowledgeBaseList(this.checkParentNode.id,this.checkParentNode,'clear')
            },50)
            // this.$emit('getKnowledge')
          }
        })
      } else if (command === 'permission'){
        this.permissionDialog = true
        if (this.nodeData.visitPermission){
          this.permissionList = JSON.parse(this.nodeData.visitPermission)
        }
        this.$nextTick(() => {
          // this.$refs.employeeStructure.permissionDialog = true;
          this.$refs.knowledgePermissions.knowledgePermissions = true;
          this.permissionTree = false;
          if (this.nodeData.visitPermission){
            this.$refs.knowledgePermissions.permissionsObj.permissionsList = JSON.parse(this.nodeData.visitPermission);
            this.$refs.knowledgePermissions.permissionsListCopy = this.nodeData.visitPermission;
          }
          let management = []
          if (this.nodeData.managePermission){
            management = JSON.parse(this.nodeData.managePermission)
          }
          this.$refs.knowledgePermissions.$refs.accountManagement.managePermission = management;
          this.$refs.knowledgePermissions.$refs.accountManagement.managePermissionIds = management.map(res => {
            return res.id
          })

          let editManagement = []
          if (this.nodeData.editPermission){
            editManagement = JSON.parse(this.nodeData.editPermission);
          }
          this.$refs.knowledgePermissions.$refs.editAccountManagement.managePermission = editManagement;
          this.$refs.knowledgePermissions.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
            return res.id
          })

          this.$refs.knowledgePermissions.permissionsObj.textWatermark = this.nodeData.textWatermark;
          this.$refs.knowledgePermissions.permissionsObj.textWatermarkValue = this.nodeData.textWatermarkValue;
          this.$refs.knowledgePermissions.permissionsObj.isDownload = this.nodeData.isDownload;
          this.$refs.knowledgePermissions.permissionsObj.downloadType = this.nodeData.downloadType;
        })
      }
    },
    loadNode(node, resolve) {
      console.debug('node',node)
      let corpId = sessionStorage.getItem('_corpId');
      let  mainId = sessionStorage.getItem('_mainId');
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      if(isAskLightning){
        url = requestUrl.knowledgeBase.clientSelectKnowledgeBaseStructureTree;
      } else {
        url = requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      }
      // let url =requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      url += "?corpId=" + corpId + '&mainId=' + mainId;
      url += '&parentId=' + (node.data.id ? node.data.id : -1)
      url += '&keyWord='
      let axiosDatas = [];
      if (node.level === 0) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data;
            axiosDatas.forEach(element => {
              element.leaf = false;
            });
            this.folderTreeCopy = axiosDatas;
            this.removeCurrentNode(this.folderTreeCopy);
            return resolve(this.folderTreeCopy);
          }
        })
      }
      if (node.level >= 1) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data
            axiosDatas.forEach(element => {
              element.leaf = false;
              element.checked=false;
            });
            this.removeCurrentNode(axiosDatas);
            node.data.children = axiosDatas;
            return resolve(axiosDatas);
          }
        })
      }
    },
    getAllParentNodes(list, id) {
      for(let i in list) {
        console.debug('list',list[i])
        if(list[i].id === id) {
          return list[i]
        }
        if(list[i].children?.length > 0) {
          let node = this.getAllParentNodes(list[i].children, id)
          if(node) return node
        }
      }
    },
    //根据员工id获取员工具体信息
    getPermissionName(list){
      let url = requestUrl.knowledgeBase.getPermissionName;
      this.$http.post(url,list).then(res => {
        if (res.data.code == '0'){
          this.permissionList = res.data.data;
        }
      })
    },
    //删除时去掉当前节点
    removeCurrentNode(treeList){
      if (!treeList || !treeList.length){
        return
      }
      for (let i=0;i<treeList.length;i++){
        if (treeList[i].id === this.nodeData.id) {
          treeList.splice(i, 1);
          break;
        }
        this.removeCurrentNode(treeList[i].children)
      }

    },
    deleteFolder(){
      let url = requestUrl.knowledgeBase.moveDeleteFolder
      let obj = {
        deleteNodeIds:[this.nodeData.id],
        newParentId:null
      }
      if (this.radio === 'remove' && this.delFolderNameList2.length > 0){
        this.judgeChildrens(this.folderTreeCopy);
        if(this.checkedDeptPopup.length === 0 && this.radio === 'remove' && this.delFolderNameList2.length > 0){
          this.$message.error(this.$t('knowledgeManagement.folderManagement.script.deleteFolderMessage'))
          return false;
        }
        obj.newParentId = this.checkedDeptPopup[0].id;
      }
      this.$http.delete(url,{
        data:obj
      }).then(res => {
        if (res.data.code == '0'){
          this.deleteFolderDialog = false;
          this.deleteFolderNoJudgeDialog = false;
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.script.deleteSuccess'),
            duration:2000,
            type:"success"
          })
          let nodeParentData = this.$refs.treeBox.getNode(this.nodeData.parentId).data;
          let children = nodeParentData.children;
          let index = children.findIndex(d => d.id === this.nodeData.id);
          children.splice(index, 1);
          if(nodeParentData.name === '企业知识库'){
            nodeParentData.haveChildren = children.length > 0 ? true : false
          }
          this.$emit('delSuccess',nodeParentData,this.nodeData.id)
          this.$refs.treeBox.setCurrentKey(this.checkParentNode.id);
        }
      })
    },
    //保存和编辑文件夹
    confirmEditFolder(){
      console.log('保存和编辑文件夹', this.nodeData.type, this.$refs.folderForm.parentChildrenNum);
      
      if (this.saveLoading){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.saving'),
          type:"warning"
        })
        return;
      }
      let url = requestUrl.knowledgeBase.saveKnowledge
      let visitPermission = this.$refs.folderForm.permissionList;
      let managePermissionList = this.$refs.folderForm.$refs.accountManagement.managePermission;
      let editPermissionList = this.$refs.folderForm.$refs.editAccountManagement.managePermission;
      let validFrom = null;
      let validTo = null;
      if (this.formFolder.takingEffectTime && this.formFolder.takingEffectTime.length > 0){
        validFrom = this.formFolder.takingEffectTime[0];
        validTo = this.formFolder.takingEffectTime[1]
      }
      let obj = {
        corpId:this.corpId,
        parentId:this.formFolder.parentId,
        name:this.formFolder.name,
        description:this.formFolder.description,
        type:0,
        visitPermissionList:visitPermission.map(res => {
          let obj = {
            id:res.id,
            type:res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
            name:res.name ? res.name : res.label
          }
          return obj
        }),
        managePermissionList,
        editPermissionList,
        textWatermark:this.$refs.folderForm.formFolders.textWatermark,
        textWatermarkValue:this.$refs.folderForm.formFolders.textWatermarkValue,
        isDownload:this.$refs.folderForm.formFolders.isDownload,
        downloadType:this.$refs.folderForm.formFolders.downloadType,
        validFrom,
        validTo
      };
      if (!obj.name){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.script.FolderRequeridMessage'),
          duration:2000,
          type:'warning'
        })
        return
      }
      if (obj.visitPermissionList.length == 0 && !this.isMoveTo){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.script.selecteVisitePermissions'),
          duration:2000,
          type:'warning'
        })
        return
      }
      this.saveLoading = true;
      if (this.formFolder.id){
        obj.type = this.nodeData.type;
        url = this.isMoveTo ? requestUrl.knowledgeBase.moveToNewHome : requestUrl.knowledgeBase.updateKnowledge;
        obj.id = this.nodeData.id;
        obj.mainId = this.nodeData.mainId;

        // 为了避免更新文件变换位置，不传orderNum
        if (obj.type != 1) {
          obj.orderNum = this.$refs.folderForm.parentChildrenNum
        }
        if(this.isMoveTo){
          obj = {
            nodeIds:[this.nodeData.id],      // "节点ID",
            newHomeId:this.formFolder.parentId,  // "要移动到的目标目录位置",
            corpId:this.corpId,     // "企业ID"
          }
          
          this.$http.post(url,obj).then(res => {
            if (res.data.code === '0'){
              this.confirmEditFolderAfterHandler(Object.assign(obj,{type:this.nodeData.type}))
              this.getKnowledgeBaseList('','','mounted')
            }
            this.saveLoading = false;
          })
        } else {
          this.$http.put(url,obj).then(res => {
            if (res.data.code === '0'){
              this.nodeData.description = this.formFolder.description
              this.nodeData.name = this.formFolder.name;
              this.nodeData.visitPermission = JSON.stringify(obj.visitPermissionList);
              this.nodeData.managePermission = JSON.stringify(obj.managePermissionList);
              this.nodeData.textWatermark = obj.textWatermark;
              this.nodeData.textWatermarkValue = obj.textWatermarkValue;
              this.nodeData.isDownload = obj.isDownload;
              this.nodeData.downloadType = obj.downloadType;
              this.nodeData.validFrom = validFrom;
              this.nodeData.validTo = validTo
              if (obj.type == 1){
                this.$emit("getFolderDetail",this.nodeData)
              }
              this.confirmEditFolderAfterHandler(obj)
              this.getKnowledgeBaseList(this.nodeParentData.id,this.nodeParentData,'clear')
            }
            this.saveLoading = false;
          })
        }
        
      } else {
        console.log('formFolder',this.formFolder)
        // 为了避免更新文件变换位置，不传orderNum
        if (obj.type != 1) {
          obj.orderNum = this.$refs.folderForm.parentChildrenNum
        }
        this.$http.post(url,obj).then(res => {
          if (res.data.code === '0'){
            this.$message({
              message:this.$t('knowledgeManagement.folderManagement.script.addFolderSucessMessage'),
              type:"success",
              duration:2000
            })
            obj.id = res.data.data;
            obj.visitPermission = JSON.stringify(obj.visitPermissionList);
            obj.managePermission = JSON.stringify(obj.managePermissionList)
            if (this.editFolderTitle == this.$t('knowledgeManagement.folderManagement.sameLevel')){
              if (this.$refs.treeBox) {
                this.$refs.treeBox.append(obj,this.nodeParentData.id);
              }
            } else if (this.editFolderTitle == this.$t('knowledgeManagement.folderManagement.script.addChildFolderSuccessMessage')){
              let currentData = this.$refs.treeBox && this.$refs.treeBox.getCurrentNode(this.nodeData.id)
              if(currentData && currentData.showChildren){
                // this.$set(currentData,'showChildren',true)
                currentData.children.push(obj)
                this.$set(currentData,'haveChildren',true);
                this.$set(currentData,'notHasChildren',false);
              }
            }
            this.$parent.$refs.knowledgeTable.getFolderAndFile(this.nodeData.id,'')
            this.editFolderDialog = false;
            this.getKnowledgeBaseList(this.nodeData.id,this.nodeData,'clear')
          }
          this.saveLoading = false;
        })
      }

    },
    confirmEditFolderAfterHandler(obj){
      let isFolder = obj.type == 0 ? '文件夹' : '文件'
      let message = this.isMoveTo ? ('移动' + isFolder + '成功') : ('编辑' + isFolder + '成功')
      this.$message({
        message:message,
        type:"success",
        duration:2000
      })
      this.editFolderDialog = false;
      // this.$emit('getKnowledge')
      if(this.isMoveTo) {

        /**
           * 移动时刷新旧的父节点/移动到的新的父节点
           * */
          // let nodeParentData = this.$refs.treeBox && this.$refs.treeBox.getNode(this.nodeParentData.id);
          let moveData = this.$refs.treeBox && this.$refs.treeBox.getNode(this.formFolder.parentId);
          if (this.nodeData.type == 0){
            // 原来节点进行删除操作
            // const children = nodeParentData.data.children;
            // const index = children.findIndex(d => d.id === this.nodeData.id);
            // children.splice(index, 1);
            this.$refs.treeBox.remove(this.nodeData.id)
            // 新节点进行增加操作
            if (!moveData.data.children) {
              this.$set(moveData.data, 'children', []);
            }
            if(moveData.data.showChildren){
              this.nodeData.parentId = moveData.data.id
              let dataCopy = JSON.parse(JSON.stringify(this.nodeData))
              dataCopy.orderNum = moveData.data.children.length;
              // moveData.data.children.push(dataCopy);
              this.$refs.treeBox.append(dataCopy, moveData.data)
              this.$refs.treeBox && this.$refs.treeBox.updateKeyChildren(moveData.data.id, moveData.data.children)
            }
            moveData.data.showChildren = false
            this.nodeParentData = JSON.parse(JSON.stringify(moveData.data))
            this.nodeExpand(moveData, moveData.data, true)

            // this.nodeExpand(this.nodeParentData, this.nodeParentData)
            // console.log(this.$refs.treeBox);
            // setTimeout(() => {
            //   this.nodeClick(newNode.data, newNode)
            // }, 2000)
            // this.getKnowledgeBaseList(nodeParentData.data.id,nodeParentData.data,'clear');
          }
          if (this.nodeData.type == 0 && moveData && moveData.data){
            // this.getKnowledgeBaseList(moveData.data.id,moveData.data,'clear');
          }
      } else {
        // this.nodeData.name = obj.name
        let newNode = this.$refs.treeBox.getNode(this.nodeData);
        console.log('newNode',newNode)
        this.nodeData.name = obj.name
        this.nodeData.visitPermission = JSON.stringify(obj.visitPermissionList)
        this.nodeData.managePermission = JSON.stringify(obj.managePermissionList)
        this.nodeData.editPermission = JSON.stringify(obj.editPermissionList)
        this.nodeData.description = obj.description;
        this.nodeData.textWatermark = obj.textWatermark;
        this.nodeData.textWatermarkValue = obj.textWatermarkValue;
        this.nodeData.isDownload = obj.isDownload;
        this.nodeData.downloadType = obj.downloadType;
        this.nodeData.validFrom = obj.validFrom;
        this.nodeData.validTo = obj.validTo;
      }
    },
    permissionNodeClick(data){
       let obj = {
          id:data.id,
          type:data.type !== undefined ? data.type : (data.source !== undefined ? 0 : 1),
          name:data.name ? data.name : data.label
        }
      if (data.checked){
        this.permissionList.push(obj)
      } else {
        this.permissionList = this.permissionList.filter(item => {
          return item.id !== data.id
        });
      }
    },
    //设置权限
    confirmPermission(obj){
      if (this.permissionTree){
        let permissionList = obj.permissionsList.map(res => {
          let obj = {
            id:res.id,
            type:res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
            name:res.label ? res.label : res.name
          }
          // obj.iframeUrl = `${this.currentUrl}?corpId=${this.corpId}&openid=${obj.name}&type=${obj.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${obj.id + ',' + obj.type + ',' + obj.name}`
          return obj
        });
        if (permissionList.length == 0){
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.script.selecteVisitePermissions'),
            type:"warning"
          })
          return;
        }
        let newObj = {
          permissionsList:permissionList,
          managePermission:obj.accountManagement,
          editPermissions:obj.editPermissions,
          textWatermark:obj.textWatermark,
          textWatermarkValue:obj.textWatermarkValue,
          isDownload:obj.isDownload,
          downloadType:obj.downloadType
        }
        this.$set(this.directoryVisitPermission,this.treeNode.index,newObj);
        this.$refs.knowledgePermissions.knowledgePermissions = false;
        this.permissionDialog = false;
        return
      }
      if (this.saveLoading){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.saving'),
          type:"warning"
        })
        return;
      }
      let permissionList = obj.permissionsList.map(res => {
        let obj = {
          id:res.id,
          type:res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
          name:res.name ? res.name : res.label
        }
        return obj
      });
      let corpId = sessionStorage.getItem('_corpId');
      let managePermission = obj.accountManagement;
      let editPermission = obj.editPermissions
      let url = requestUrl.knowledgeBase.permissionSetV2 + '?id=' + this.nodeData.id + '&corpId=' + corpId;
      let param = {
        visitPermission:permissionList,
        managePermission,
        editPermission,
        textWatermark:obj.textWatermark,
        textWatermarkValue:obj.textWatermarkValue,
        isDownload:obj.isDownload,
        downloadType:obj.downloadType
      }
      if (permissionList.length == 0){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.script.selecteVisitePermissions'),
          type:"warning"
        })
        return;
      }
      this.saveLoading = true;
      this.$http.post(url,param).then(res => {
        if (res.data.code == '0'){
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.permissionSaveSuccess'),
            type:'success',
            duration:2000
          })
          this.$emit('getKnowledge')
          // this.$refs.employeeStructure.permissionDialog = false;
          this.$refs.knowledgePermissions.knowledgePermissions = false;
          this.permissionDialog = false;
          this.getKnowledgeBaseList(this.checkParentNode.id,this.checkParentNode,'clear');
          this.saveLoading = false;
        }
      })
    },
    //取消设置权限
    cancelPermission(){
      this.permissionDialog = false;
      this.permissionDialogTree = false;
    },
    //目录管理
    handleDirectory(){
      this.directoryDialog = true;
      this.textarea = "";
    },
    closeDirectoryDialog(){
      this.directoryDialog = false;
      this.directoryTree = [];
      this.directoryVisitPermission = {};
    },
    /*全量加载请求左侧列表*/
    getKnowledgeBaseTree(){
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      let url = requestUrl.knowledgeBase.knowledgeBaseTree;
      url += "?corpId=" + corpId + '&parentId=-1' + '&mainId=' + mainId;
      this.$http.get(url).then(res => {
        if (res.data.code == '0'){
          this.folderPullTreeCopy = JSON.stringify(res.data.data)
          // let list = res.data.data ? res.data.data : [];
          // let folderFullTree = list;
          // this.textarea = "";
          // let newData = this.getFlatArr(folderFullTree);
          // let textarea = newData.map(res => {
          //   return res.name
          // })
          // textarea.forEach(item => {
          //   this.textarea += item +'\n'
          // })
        }
      })
    },
    getFlatArr(arr) {
      return arr.reduce((val, item) => {

        let flatArr = [...val, item];
        // 可以在此处限制各种需要的条件，在展示字段前加空格，——之类的，以及其它情况

        if (item.children) {
          item.children.forEach(items => {
            if (item.name.indexOf('##')!==-1){
              let index = item.name.lastIndexOf('#') + 1
              items.name = item.name.slice(0,index) + '##' + items.name
            } else {
              items.name = '##' + items.name
            }
          })
          flatArr = [...flatArr, ...this.getFlatArr(item.children)];
        }
        return flatArr;
      }, []);
    },
    onNodeClick(data){
      this.getAllNodeId(this.folderTreeCopy,data);
    },
    // 递归获取所有节点
    getAllNodeId (moduleDataList,data) {
      return new  Promise((resolve) => {
        if (moduleDataList){
          for (let i = 0; i < moduleDataList.length; i++) {
            if(data.id === moduleDataList[i].id){
              this.$set(moduleDataList[i],'checked',!moduleDataList[i].checked);
            } else {
              this.$set(moduleDataList[i],'checked',false);
            }
            if (moduleDataList[i].children && moduleDataList[i].children.length > 0) {
              this.getAllNodeId( moduleDataList[i].children,data)
            }
          }
        }
        resolve(true)
      })
    },
    // 递归取出checked=true的数据
    judgeChildrens(data) {
      data.forEach(item => {
        if (item.checked) {
          this.checkedDeptPopup.push(item);
        }
        if (item.children && item.children.length > 0) {
          this.judgeChildrens(item.children || []);
        }
      });
      return
    },
    parseMarkdown(text) {
      const lines = text.split('\n').filter(item => item);
      const stack = [];
      lines.forEach((line,index) => {
        const level = line.match(/^#+/);
        const name = line.replace(/^#+/, '').trim();
        if (level) {
          const node = {
            name,
            children: [],
            index
          };
          let parent = stack[stack.length - 1];
          if (parent){
            while (parent && parent.level >= (level[0].length)) {
              stack.pop();
              parent = stack[stack.length - 1];
            }
            parent.children.push(node);
            stack.push({
              level: level[0] && level[0].length,
              children: node.children
            });
          }
        } else {
          stack.push({
            name:line,
            index:index,
            children:[]
          })
        }
      });
      this.directoryTree = stack.filter(item => item.name);
      this.setInitPermission()
    },
    setInitPermission() {
        console.log(this.directoryVisitPermission, "this.directoryTree")
    },
    //打开选择可访问权限
    selectPermission(row){
      if (!this.directoryVisitPermission[row.index]){
        this.$set(this.directoryVisitPermission,row.index,{})
      }
      this.treeNode = row;
      this.permissionDialog = true;
      this.$nextTick(() => {
        this.$refs.knowledgePermissions.knowledgePermissions = true;
        this.permissionTree = true;
        this.$refs.knowledgePermissions.permissionsObj.permissionsList = this.directoryVisitPermission[row.index].permissionsList ? JSON.parse(JSON.stringify(this.directoryVisitPermission[row.index].permissionsList)) : [];
        this.$refs.knowledgePermissions.permissionsListCopy = this.directoryVisitPermission[row.index].permissionsList ? JSON.stringify(this.directoryVisitPermission[row.index].permissionsList) : "[]";
        this.$refs.knowledgePermissions.permissionsObj.textWatermark = this.directoryVisitPermission[row.index].textWatermark;
        this.$refs.knowledgePermissions.permissionsObj.textWatermarkValue = this.directoryVisitPermission[row.index].textWatermarkValue;
        this.$refs.knowledgePermissions.permissionsObj.isDownload = this.directoryVisitPermission[row.index].isDownload;
        this.$refs.knowledgePermissions.permissionsObj.downloadType = this.directoryVisitPermission[row.index].downloadType;
        let management = []
        if (this.directoryVisitPermission[row.index] && this.directoryVisitPermission[row.index].managePermission){
          management = this.directoryVisitPermission[row.index].managePermission
        }
        this.$refs.knowledgePermissions.$refs.accountManagement.managePermission = management;
        this.$refs.knowledgePermissions.$refs.accountManagement.managePermissionIds = management.map(res => {
          return res.id
        })

        let editManagement = []
        if (this.nodeData.editPermission){
          editManagement = JSON.parse(this.nodeData.editPermission);
        }
        this.$refs.knowledgePermissions.$refs.editAccountManagement.managePermission = editManagement;
        this.$refs.knowledgePermissions.$refs.editAccountManagement.managePermissionIds = editManagement.map(res => {
          return res.id
        })
      })

    },
    delPermission(list,index){
      list.splice(index,1);
    },
    /**
     * 目录管理中的权限设置
     * */
    confirmPermissionTree(){
      let permissionList = this.permissionList.map(res => {
        let obj = {
          id:res.id,
          type:res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
          name:res.label ? res.label : res.name
        }
        // obj.iframeUrl = `${this.currentUrl}?corpId=${this.corpId}&openid=${obj.name}&type=${obj.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${obj.id + ',' + obj.type + ',' + obj.name}`
        return obj
      });
      this.$set(this.directoryVisitPermission,this.treeNode.index,permissionList)
      this.permissionDialogTree = false;
    },
    tabFunc(){
      this.insertInputTxt('myInput', '##')
    },
    insertInputTxt (id, insertTxt) {
      var elInput = document.getElementById(id)
      var startPos = elInput.selectionStart
      var endPos = elInput.selectionEnd
      if (startPos === undefined || endPos === undefined) return
      var txt = elInput.value
      var result = txt.substring(0, startPos) + insertTxt + txt.substring(endPos)
      elInput.value = result
      elInput.focus()
      elInput.selectionStart = startPos + insertTxt.length
      elInput.selectionEnd = startPos + insertTxt.length
    },
    async confirmDirectory(){
      await this.mergeTreeId(this.directoryTree,this.folderTree[0] ? this.folderTree[0].id : -1  );
      this.saveBatch();
    },
    // 企业知识通过目录管理创建目录时，设置了一级目录的权限，一级目录权限下的二级目录也应跟随一级目录权限
    handlerVisitPermission(list,visitPermission){
      list.forEach(item => {
        if(item.visitPermission && item.visitPermission !== ''){
          console.log('无需处理');
        } else {
          item.visitPermission = visitPermission;
        }
        // if(item.children && item.children.length > 0){
        //   this.handlerVisitPermission(item.children,item.visitPermission,item.textWatermark,item.textWatermarkValue,item.visitorWatermark);
        // }
      })
      return list;
    },
    /**
     * 目录管理的保存
     * */
    async saveBatch(){
      // let directoryTreeCopy = this.handlerVisitPermission(JSON.parse(JSON.stringify(this.directoryTree)));
      const lines = this.textarea.split('\n');
      if (lines && lines[0] && lines[0].substr(0,2) == '##'){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.firstFolderTips'),
          duration:2000,
          type:"warning"
        })
        return
      }
      let isPermission = await this.checkVisitPermission(this.directoryTree);
      if (isPermission == false){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.permissionNotNull'),
          type:"warning"
        })
        return;
      }
      if (this.saveLoading){
        this.$message({
          message:"正在保存中...",
          type:"warning"
        })
        return;
      }
      let parentData = null;
      let directoryTree = JSON.parse(JSON.stringify(this.directoryTree))
      await this.setPermission(directoryTree,parentData);
      this.saveLoading = true;
      let id = this.folderTree[0] ? this.folderTree[0].id : -1
      let url = requestUrl.knowledgeBase.saveBatch + '?rootNodeId=' + id;
      this.$http.post(url,directoryTree).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.folderManagement.saveSuccess'),
            duration:2000,
            type:"success"
          })
          this.directoryDialog = false;
          this.saveLoading = false;
          this.getKnowledgeBaseList('','','mounted')
          this.directoryTree = [];
          this.directoryVisitPermission = {};
        }
      })
    },
    /**
     * 目录管理新增的数据添加临时id和父id
     * */
    mergeTreeId(data,parentId){
      for(var i = 0; i < data.length; i++){
        if (!data[i].id){
          data[i].temporaryId = uuidv4().toString().replaceAll("-", "");
        }
        if (!data[i].parentId){
          if (parentId == -1){
            data[i].parentId = -1;
          } else {
            data[i].temporaryParentId = parentId;
          }
        }
        if (data[i].orderNum == undefined){
          data[i].orderNum = i;
        }
        if (!data[i].type){
          data[i].type = 0;
        }
        if (!data[i].corpId){
          data[i].corpId = sessionStorage.getItem('_corpId');
        }
        if (!data[i].visitPermission){
          data[i].visitPermission = ""
        }
        if (!data[i].managePermission){
          data[i].managePermission = ""
        }
        if (data[i].index != undefined && data[i].index != null){
          if (this.directoryVisitPermission[data[i].index] && this.directoryVisitPermission[data[i].index].permissionsList) {
            data[i].visitPermission = JSON.stringify(this.directoryVisitPermission[data[i].index].permissionsList);
          }
          if (this.directoryVisitPermission[data[i].index] && this.directoryVisitPermission[data[i].index].managePermission) {
            data[i].managePermission = JSON.stringify(this.directoryVisitPermission[data[i].index].managePermission);
          }
          // delete data[i].index;
        }
        let temporaryParentId = data[i].id ? data[i].id : data[i].temporaryId;
        this.mergeTreeId(data[i].children,temporaryParentId)
      }
      return data
    },
    checkVisitPermission(data){
      for(var i = 0; i < data.length; i++){
        if (!data[i].visitPermission){
          return false
        }
        // let temporaryParentId = data[i].id ? data[i].id : data[i].temporaryId;
        // this.checkVisitPermission(data[i].children);
      }

    },
    setPermission(data,parentData){
      for(var i = 0; i < data.length; i++){
        if (parentData && !data[i].visitPermission){
          data[i].visitPermission = parentData.visitPermission
        }
        if (parentData && !data[i].managePermission){
          data[i].managePermission = parentData.managePermission
        }
        parentData = data[i];
        this.setPermission(data[i].children,parentData);
      }
    },
    filterNode(value,data){
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    /**
     * 使用到全部  包括位置和权限
     * 使用type区分是位置还是权限
     * */
    userAll(row){
      this.selectedData(this.directoryTree,row)
    },
    /**
     * paste 粘贴复制的位置
     * */
    paste(row){
      if (Object.keys(this.duplicate).length == 0){
        this.$message({
          message:this.$t('knowledgeManagement.folderManagement.copyTips'),
          type:"warning",
          duration:2000
        })
        return
      }
      if (!this.directoryVisitPermission[row.index]){
        this.$set(this.directoryVisitPermission,row.index,{})
      }
      this.directoryVisitPermission[row.index] = this.duplicate;
    },
    /**
     * duplicateData 复制位置或者权限
     * */
    duplicateData(row){
      if (this.directoryVisitPermission[row.index]){
        this.duplicate = JSON.parse(JSON.stringify(this.directoryVisitPermission[row.index]))
      }
    },
    selectedData(list,data){
      list.forEach(item => {
        if (!this.directoryVisitPermission[item.index]){
          this.$set(this.directoryVisitPermission,item.index,{})
        }
        this.directoryVisitPermission[item.index] = JSON.parse(JSON.stringify(this.directoryVisitPermission[data.index]))
        if (item.children){
          this.selectedData(item.children,data)
        }
      })
      console.log(data,this.directoryVisitPermission)
    },
    hasManagement(data){
      let bool = false;
      let roleId = sessionStorage.getItem('roleId')
      if (roleId == 1){
        return true
      }
      if (data.managePermission && JSON.parse(data.managePermission)){
        let grouIds = sessionStorage.getItem('_groupIds') ? JSON.parse(sessionStorage.getItem('_groupIds')) : [];
        let userId = sessionStorage.getItem('_uid') ? Number(sessionStorage.getItem('_uid')) : ""
        if (grouIds){
          grouIds = grouIds.map(res => {
            return Number(res)
          })
        }
        let managePermission = JSON.parse(data.managePermission).map(res => {
          return res.id
        });
        if (managePermission.includes(userId)){
          bool = true;
          return bool
        } else if (grouIds){
          for (let i=0;i<grouIds.length;i++){
            if (managePermission.includes(grouIds[i])){
              bool = true
              return bool;
            }
          }
        }
      }
      return bool
    }
  },
  mounted() {

    this.corpId = sessionStorage.getItem('_corpId');
    this.getKnowledgeBaseList('','','mounted');
  },
  watch:{
    textarea:{
      handler(newVal){
        this.parseMarkdown(newVal);
      },
    },
    checkDefault: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // document.querySelector('.el-tree-node__content').click()
          })
        }
      },
      immediate: true,
    },
    corpId:{
      handler(){
        // this.getKnowledgeBaseList('');
      },
      immediate:true,
      deep:true
    },
  }
};
</script>

<style scoped lang="less">
#folderManagement {
  padding: 12px;
  font-size: 14px;
  height: calc(100% - 70px);
  .folderSearch {
    /deep/ .el-input__inner {
      border-radius: 16px;
      font-size: 13px;
    }
  }

  .folderList {
    height: calc(100% - 50px);
    margin-top: 14px;
    .create-knowledge {
      height: 38px;
      background: #F5F7FB;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin: 12px 0;
      .guoran-jiahaotianjia{
        color:#366aff;
        cursor: pointer;
      }
    }
    .knowledge-name i {
      margin-right: 6px;
      color: #366AFF;
    }
    .treeBox{
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      .knowledgeTitle{
        margin-bottom: 8px;
        color: #999999;
      }
      .tree-item{
        display: flex;
        flex-direction: row;
        width: 100%;
        .tree-item-name{
          display: flex;
          align-items: center;
          .folder-name{
            margin-left: 6px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .synchronizeTree{
          width: 100%;
          .folder-name{
            max-width: 80%;
          }
        }
        .folderTree{
          width: calc(100% - 50px);
          .folder-name{
            max-width: calc(100% - 26px);
          }
        }
        .guoran-tongyichicun-wenjian,.guoran-jiahaotianjia{
          color: #366aff;
        }
        .el-dropdown{
          width:20px;
          flex:none;
        }
        .guoran-qita{
          display: none;
        }
      }
      /deep/.el-tree-node__expand-icon{
        display: none;
        font-size: 14px;
      }
      .el-icon-caret-right{
        padding: 0 6px;
        color: #c0c4cc;
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      .el-icon-caret-right{
        &.down{
          transform: rotate(90deg);
        }
        &.right{
          transform: rotate(0);
        }
      }
    }
    .el-tree-node__content:hover{
      .guoran-qita{
        display: block;
      }
    }
    /deep/.el-tree-node__content{
      height: 36px !important;
    }
    /deep/.is-current>.el-tree-node__content{
      background: #F8FAFF;
      position: relative;
      color: #366AFF;
    }
    /deep/.is-current>.el-tree-node__content::before{
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      background: #366AFF;
      top: 0;
      left: 0;;
    }
  }
  .askLightFolderList{
    margin-top: 0!important;
    height: 100% !important;
  }
  .ruleForms{
    .custom-tree-node-choose-dept{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      i{
        font-weight: 600;
      }
      .custom-tree-node-left{
        display: flex;
        align-items: center;
      }
    }
  }
}
.directoryContainer{
  display: flex;
  width: 100%;
  .directoryTree{
    flex: none;
    width: 50%;
    .custom-tree-node{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .custom-tree-node-left{
        max-width: 130px;
        flex: none;
        margin: 10px 10px 10px 0;
        overflow: hidden;
        text-overflow:ellipsis;
        .custom-tree-node-label{
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          max-width: 130px;
        }
      }
      .custom-tree-node-right{
        max-width: calc(100% - 140px);
        min-width: 240px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid #E0E6F7;
        border-radius: 5px;
        padding: 0px 12px;
        height: 38px;
        overflow: hidden;
        .permission{
          flex: auto;
          display: flex;
          align-items: center;
          //flex-wrap: wrap;
          width: calc(100% - 24px);
          overflow: hidden;
          justify-content: space-between;
          .permissionItem-list{
            display: flex;
            align-items: center;
          }
          .permissionItem{
            display: flex;
            align-items: center;
            margin-right: 8px;
            background-color: #E8EDF7;
            border-radius: 3px;
            padding: 0px 4px;
            font-size: 12px;
            color: #010101;
            height: 26px;
            .avatar{
              flex: none;
              width: 21px;
              height: 21px;
              background: #366AFF;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              font-size: 12px;
              i{
                font-size: 14px;
              }
            }
            .departAvatar{
              flex: none;
              width: 21px;
              height:21px;
              background: #918EFF;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              i{
                color: #FFFFFF;
                font-size: 12px;
              }
            }
            .label{
              padding: 0px 4px;
              max-width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 0px;
            }
            iframe{
              height: 22px;
              cursor: pointer;
            }
          }
          .operateBtn{
            flex: none;
            width: 60px;
            display: none;
            align-items: center;
            justify-content: space-between;
            //margin-right: 10px;
            i{
              color: #366aff;
              cursor: pointer;
            }
          }
        }
      }
      .custom-tree-node-right:hover{
        .operateBtn{
          display: flex;
        }
      }
    }
    /deep/.el-tree-node__content{
      padding: 10px 0;
    }
    .header-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 24px;
      margin-bottom: 12px;
      .header-left{
        width: 130px;
      }
      .header-right{
        max-width: calc(100% - 140px);
        min-width: 260px;
        flex: none;
      }
    }
    /deep/.el-tree{
      margin-left: 20px;
    }
  }
  .directoryInput{
    flex: none;
    width: 50%;
  }
}
.ruleForms{
  .del-tips{
    height: 42px;
    background: #FFF8F4;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #FF9555;
    text-align: left;
    line-height: 42px;
    padding: 0 10px;
  }
}
.directoryDialog{
  /deep/.defaultDialog{
    #popup-assembly{
      width: 70%;
    }
  }
}
</style>